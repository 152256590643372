<template>
  <table class="table table-striped mb-4">
    <thead>
      <tr>
        <th width="10%">Name</th>
        <th width="9%">Start Date</th>
        <th v-if="rates.some(rate => rate.calculatedStartDate)"></th>
        <th width="9%">End Date</th>
        <th v-if="rates.some(rate => rate.calculatedEndDate)"></th>
        <th width="3%">Consumption</th>
        <th v-if="rates.some(rate => rate.calculatedConsumption)"></th>
        <th width="7%">Unit Rate</th>
        <th v-if="rates.some(rate => rate.calculatedUnitRate)"></th>
        <th width="7%">Cost</th>
        <th v-if="rates.some(rate => rate.calculatedCost)"></th>
        <th v-if="rates.some(rate => rate.friendlyStartRead)" :width="editMode ? '35%' : '20%'">Reads</th>
        <th v-if="rates.some(rate => rate.calculatedStartRead)"></th>
        <th v-if="rates.some(rate => rate.calculatedEndRead)"></th>
        <th v-if="editMode" width="5%">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="rate in filteredRates" :key="rate.id">
        <td>
          <div v-if="editMode">
            <FormItem :id="rate.id" type="text" :value="rate.rateName" @input="input => onChangeRate(input, rate.id, 'rateName')" />
          </div>
          <div v-else>{{ !rate.rateName ? 'N/A' : rate.rateName }}</div>
        </td>

        <td class="border-left">
          <div v-if="editMode">
            <FormItem :id="rate.id" type="datePicker" :value="rate.startDate" @input="input => onChangeRate(input, rate.id, 'startDate')" />
          </div>
          <div v-else>{{ rate.friendlyStartDate === null ? 'N/A' : rate.friendlyStartDate }}</div>
        </td>
        <td v-if="rate.calculatedStartDate" class="font-w600 text-info">{{ rate.calculatedStartDate }}</td>

        <td class="border-left">
          <div v-if="editMode">
            <FormItem :id="rate.id" type="datePicker" :value="rate.endDate" @input="input => onChangeRate(input, rate.id, 'endDate')" />
          </div>
          <div v-else>{{ rate.friendlyEndDate === null ? 'N/A' : rate.friendlyEndDate }}</div>
        </td>
        <td v-if="rate.calculatedEndDate" class="font-w600 text-info">{{ rate.calculatedEndDate }}</td>

        <td class="border-left">
          <div v-if="editMode">
            <FormItem :id="rate.id" type="text" :value="rate.friendlyConsumption" @input="input => onChangeRate(input, rate.id, 'consumption')" />
          </div>
          <div v-else>{{ rate.friendlyConsumption === null ? 'N/A' : rate.friendlyConsumption }}</div>
        </td>
        <td v-if="rate.calculatedConsumption" class="font-w600 text-info">{{ rate.calculatedConsumption }}</td>

        <td class="border-left">
          <div v-if="editMode">
            <FormItem :id="rate.id" type="text" :value="rate.friendlyUnitRate" @input="input => onChangeRate(input, rate.id, 'unitRate')" />
          </div>
          <div v-else>{{ rate.friendlyUnitRate === null ? 'N/A' : rate.friendlyUnitRate }}</div>
        </td>
        <td v-if="rate.calculatedUnitRate" class="font-w600 text-info">{{ rate.calculatedUnitRate }}</td>

        <td class="border-left">
          <div v-if="editMode">
            <FormItem :id="rate.id" type="text" :value="rate.friendlyCost" @input="input => onChangeRate(input, rate.id, 'cost')" />
          </div>
          <div v-else>{{ rate.friendlyCost === null ? 'N/A' : rate.friendlyCost }}</div>
        </td>
        <td v-if="rate.calculatedCost" class="font-w600 text-info">{{ rate.calculatedCost }}</td>

        <td v-if="rates.some(rate => rate.friendlyStartRead)" class="border-left">
          <div v-for="rateRead in rateReads(rate)" :key="rateRead.id" class="row">
            <div class="form-group" :class="displayRegister ? 'col-5' : 'col-7'">
              <div>
                <label>{{ rateRead.label }}</label>
                <div v-if="editMode" class="input-group">
                  <FormItem :id="rateRead.id" type="text" :value="rateRead.value" @input="input => onChangeRate(input, rateRead.id, rateRead.type)" />
                  <div class="input-group-append">
                    <FormItem
                      :id="rateRead.id"
                      v-model="rateRead.unit"
                      :options="unitOptions"
                      type="select"
                      :is-alt="true"
                      @input="input => onChangeRate(input, rateRead.id, `${rateRead.type}Unit`)"
                    />
                  </div>
                </div>
                <div v-else>{{ rateRead.value || 'N/A' }} {{ unitOptions.find(unit => unit.value === rateRead.unit)?.label }}</div>
              </div>
            </div>
            <div class="form-group" :class="displayRegister ? 'col-3' : 'col-5'">
              <label> Type </label>
              <div v-if="editMode" class="input-group">
                <FormItem
                  :id="rateRead.id"
                  type="text"
                  :value="rateRead.readType"
                  @input="input => onChangeRate(input, rateRead.id, `${rateRead.type}Type`)"
                />
              </div>
              <div v-else>{{ rateRead.readType || 'N/A' }}</div>
            </div>

            <div v-if="displayRegister" class="form-group col-3">
              <label> Register </label>
              <div v-if="editMode" class="input-group">
                <FormItem
                  :id="rateRead.id"
                  type="select"
                  :value="rateRead.register"
                  :options="registerIds"
                  @input="input => onChangeRate(input, rateRead.id, `${rateRead.type}Register`)"
                />
              </div>
              <div v-else>{{ rateRead.register || 'N/A' }}</div>
            </div>
          </div>
        </td>

        <td v-if="editMode" class="border-left text-center">
          <div class="btn btn-link text-danger" @click="() => onClickDeleteRate(rate)"><i class="fa fa-times"></i></div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import FormItem from './FormItem';

export default {
  name: 'InvoiceResultsRateTable',
  components: {
    FormItem
  },
  props: {
    rates: {
      type: Array,
      required: true
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    },
    form: {
      type: Array,
      required: false,
      default: () => ({})
    },
    registerIds: {
      type: Array,
      required: false,
      default: () => []
    },
    unitOptions: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    filteredRates() {
      return this.editMode ? this.rates.filter(rate => this.form.some(formRate => formRate.id === rate.id)) : this.rates;
    },
    displayRegister() {
      return this.registerIds.length > 1;
    }
  },
  methods: {
    rateReads(rate) {
      const reads = [];

      if (this.rates.some(rate => rate.friendlyStartRead)) {
        reads.push({
          ...rate,
          type: 'startRead',
          value: rate.friendlyStartRead,
          unit: rate.startReadUnit || 'N/A',
          readType: rate.friendlyStartReadType || 'N/A',
          register: rate.startReadRegister || 'N/A',
          label: 'Start Read'
        });
      }

      if (this.rates.some(rate => rate.friendlyEndRead)) {
        reads.push({
          ...rate,
          type: 'endRead',
          value: rate.friendlyEndRead,
          unit: rate.endReadUnit || 'N/A',
          readType: rate.friendlyStartEndType || 'N/A',
          register: rate.endReadRegister || 'N/A',
          label: 'End Read'
        });
      }

      return reads;
    },
    onChangeRate(value, id, key) {
      const rates = [...this.form];

      const rate = rates.find(rate => rate.id === id);

      rate[key] = value;

      this.$emit('change', rates);
    },
    onClickDeleteRate(rate) {
      this.$emit(
        'change',
        this.form.filter(formRate => formRate.id !== rate.id)
      );
    }
  }
};
</script>
